// Modules
import React from "react";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import LoanOfficerSearch from "../../../components/loan-officers/loan-officer-search";

const SpanishContactLoanOfficer = ({ location }) => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      active: true,
      title: "Encontrar un oficial de préstamo"
    }
  ];

  const SEOData = {
    title: "Encontrar un oficial de préstamo",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Encontrar un oficial de préstamo"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Encuentra un oficial de préstamo cerca de ti. Es fácil hacer una cita con su oficial de préstamos local o iniciar una solicitud de préstamo en línea."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/contacte-a-un-asesor-hipotecario"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const locanOfficerSearchData = {
    location: { ...location },
    language: "es"
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <LoanOfficerSearch {...locanOfficerSearchData} />
    </SecondaryLanding>
  );
};

export default SpanishContactLoanOfficer;
